body {
  margin: 0;
  font-family: 'Nunito';
  background-color: #f96c69;
}

#banner {
  background-color: #f96c69;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#banner > img {
  width: 500px;
}

#about-book {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#about-book > div {
  width: 1300px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

#about-book-text {
  width: 750px;
}

#about-book-heading {
  font-size: 40px;
  color: #f96c69;
  margin-bottom: 20px;
}

#about-book-desc {
  font-size: 25px;
  color: #f96c69;
  margin-bottom: 20px;
}

#about-book-charity {
  font-size: 25px;
  color: #f96c69;
}

#about-book-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  justify-content: center;
  padding: 20px;
}

#buy-book {
  font-size: 32px;
  color: white;
  background-color: #f96c69;
  border: none;
  padding: 20px;
  border-radius: 15px;
  width: 250px;
  cursor: pointer;
}

#donations {
  background-color: #f96c69;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

#donations-heading {
  font-size: 40px;
  margin-bottom: 20px;
}

#total-donated {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

table {
  border-collapse: collapse;
}

.tr-border {
  border-bottom: 1px solid white;
}

td {
  padding: 5px 5px 5px 5px;
  font-size: 18px;
}

.charity {
  padding-right: 20px;
}

.dollar {
  color: #69B75E;
}

#about-me {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#about-me > div {
  width: 1300px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

#me {
  display: flex;
  align-items: center;
  justify-content: center;
}

#me > img {
  padding: 20px;
  border-radius: 400px;
  width: 300px;
}

#copyright {
  text-align: center;
  background-color: #f96c69;
  color: white;
  padding: 10px;
}

#about-book-button img {
  width: 130px;
  margin-bottom: 30px;
}

#about-book-button a {
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  #about-book > div {
    width: 80%;
  }

  #about-me > div {
    width: 80%;
  }

  #buy-book {
    font-size: 30px;
    padding: 15px;
    width: 210px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1100px) {
  #about-book > div {
    flex-direction: column;
  }

  #about-me > div {
    flex-direction: column;
  }

  #about-book-button {
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  #about-book {
    height: unset;
    padding: 50px 0;
  }

  #about-book-text {
    margin-bottom: 20px;
    width: 100%;
  }

  #about-me {
    height: unset;
    padding: 50px 0;
  }

  #about-book-heading {
    font-size: 34px;
  }

  #about-book-desc,
  #about-book-charity {
    font-size: 22px;
  }

  #banner > img {
    width: 80%;
    height: unset;
  }

  #donations-heading {
    font-size: 34px;
  }

  #total-donated {
    font-size: 25px;
  }

  #buy-book {
    font-size: 24px;
    padding: 15px;
    width: 180px;
  }

  td {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  #me > img {
    width: 80%;
  }
}

@media screen and (max-height: 900px) and (min-width: 600px) {
  #banner img {
    width: unset;
    height: 100%;
  }
}

@media screen and (max-height: 600px) and (min-width: 600px) {
  #about-book {
    height: unset;
    padding: 100px 0;
  }

  #about-me {
    height: unset;
    padding: 100px 0;
  }

  #about-book-text {
    margin-bottom: 20px;
    width: 100%;
  }

  #about-book-heading {
    font-size: 34px;
  }

  #about-book-desc,
  #about-book-charity {
    font-size: 22px;
  }

  #donations-heading {
    font-size: 34px;
  }

  #total-donated {
    font-size: 25px;
  }

  #buy-book {
    font-size: 24px;
    padding: 15px;
    width: 180px;
  }

  td {
    font-size: 16px;
  }
}

@media screen and (max-height: 600px) {
  #banner {
    height: unset;
  }
}

#email {
  background-color: #f96c69;
  text-decoration: none;
  color: white;
  padding: 3px 10px;
  border-radius: 6px;
}